@import "~swiper/swiper.min.css";
* {
  margin: 0;
  padding: 0;
}

.navigation-btn-text {
  font-size: 24px;
  line-height: 29px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-feature-settings: "pnum" on, "lnum" on;
  letter-spacing: 0.04em;
  font-weight: 400;
  color: #D1AD8B;
}

.mti-h1 {
  font-size: 96px;
  line-height: 117px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-feature-settings: "pnum" on, "lnum" on;
  letter-spacing: 0.04em;
  font-weight: 400;
  color: #D1AD8B;
}

.mti-h2 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 78px;
  letter-spacing: 0.04em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #D1AD8B;
}

.mti-span {
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.04em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #D1AD8B;
}

.mti-span2 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 44px;
  color: #524A42;
}

.tile-inc {
  font-size: 32px;
  line-height: 39px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-feature-settings: "pnum" on, "lnum" on;
  letter-spacing: 0.04em;
  font-weight: 400;
  color: #D1AD8B;
}

.mti-h3 {
  font-size: 20px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-feature-settings: "pnum" on, "lnum" on;
  letter-spacing: 0.04em;
  font-weight: 400;
  color: #D1AD8B;
}

.mti-h4 {
  font-size: 24px;
  line-height: 29px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-feature-settings: "pnum" on, "lnum" on;
  letter-spacing: 0.04em;
  font-weight: 400;
  color: #D1AD8B;
}
@media screen and (max-width: 768px) {
  .mti-h4 {
    font-size: 16px;
    line-height: 20px;
  }
}

.mti-body-mobile {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #524A42;
}

.mti-subtitle {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 59px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #D1AD8B;
}
@media screen and (max-width: 768px) {
  .mti-subtitle {
    font-size: 36px;
    line-height: 44px;
  }
}

.submit-btn {
  background-color: transparent;
  border: 2px solid #D1AD8B;
  height: 106px;
  width: 259px;
  border-radius: 51% 49% 50% 50%/50% 50% 50% 50%;
  position: relative;
}
.submit-btn img {
  position: absolute;
  left: -93px;
  top: 43px;
  margin-left: auto;
  margin-right: auto;
}
.submit-btn:hover {
  cursor: pointer;
  opacity: 0.5;
}
@media screen and (max-width: 768px) {
  .submit-btn {
    width: 160px;
    height: 64px;
    border: 1px solid #D1AD8B;
  }
  .submit-btn img {
    left: -130px;
    top: 24px;
  }
}