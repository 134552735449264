@import "color-variables";

.submit-btn {
  background-color: transparent;
  border: 2px solid $mti-beige-accent;
  height: 106px;
  width: 259px;
  border-radius: 51% 49% 50% 50% / 50% 50% 50% 50%;
  position: relative;

  img {
    position: absolute;
    left: -93px;
    top: 43px;
    margin-left:auto;
    margin-right:auto;
  }

  &:hover {
    cursor: pointer;
    opacity: .5;
  }

  @media screen and (max-width: 768px) {
    width: 160px;
    height: 64px;
    border: 1px solid $mti-beige-accent;

    img {
      left: -130px;
      top: 24px;
    }
  }
}
