@import 'color-variables';

.navigation-btn-text {
  font-size: 24px;
  line-height: 29px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-feature-settings: 'pnum' on, 'lnum' on;
  letter-spacing: 0.04em;
  font-weight: 400;
  color: $mti-beige-accent;
}

.mti-h1 {
  font-size: 96px;
  line-height: 117px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-feature-settings: 'pnum' on, 'lnum' on;
  letter-spacing: 0.04em;
  font-weight: 400;
  color: $mti-beige-accent;
}

.mti-h2 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 78px;
  letter-spacing: 0.04em;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color:  $mti-beige-accent;
}

.mti-span {
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.04em;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: $mti-beige-accent;
}

.mti-span2 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 44px;
  color: $mti-accent-3;
}

.tile-inc {
  font-size: 32px;
  line-height: 39px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-feature-settings: 'pnum' on, 'lnum' on;
  letter-spacing: 0.04em;
  font-weight: 400;
  color: $mti-beige-accent;
}

.mti-h3 {
  font-size: 20px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-feature-settings: 'pnum' on, 'lnum' on;
  letter-spacing: 0.04em;
  font-weight: 400;
  color: $mti-beige-accent;
 }

.mti-h4 {
  font-size: 24px;
  line-height: 29px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-feature-settings: 'pnum' on, 'lnum' on;
  letter-spacing: 0.04em;
  font-weight: 400;
  color: $mti-beige-accent;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.mti-body-mobile {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: $mti-accent-3;
}

.mti-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 59px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: $mti-beige-accent;

  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 44px;
  }
}
